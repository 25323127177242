export class Hotel {
    id: string;
    name: string;
    globalCode: string;
    sabreRating: number;
    isActive: boolean;
    adressLine: string;
    brand: HotelBrand;
    rate: HotelRate;

    constructor(data: any) {
        this.id = data.id;
        this.name = data.name || "";
        this.globalCode = data.code_global || "";
        this.sabreRating = data.sabre_rating || 0;
        this.isActive = data.is_active || false;
        this.adressLine = data.address || "";
        this.brand = data.brand || null;
        this.rate = (data.rate && new HotelRate(data.rate)) || null;
    }
    static getStatusLabel = (status = false) => {
        return status ? (
            <svg className="h-4 w-4">
                <use xlinkHref="/svg-sprite.xml#check-circle"></use>
            </svg>
        ) : (
            <svg className="h-4 w-4">
                <use xlinkHref="/svg-sprite.xml#minus-circle"></use>
            </svg>
        );
    };
}

export type HotelBrand = {
    id: string;
    name: string;
};

export class HotelRate {
    avgNightRate: number;
    totalRate: number;
    startDate: string;
    endDate: string;
    currencyCode: string;

    constructor(data: any) {
        this.avgNightRate = data.avg_night_rate || null;
        this.totalRate = data.total_rate || null;
        this.startDate = data.start_date || "";
        this.endDate = data.end_date || "";
        this.currencyCode = data.currency_code || "";
    }
}
