import { useEffect } from "react";
import getConfig from "next/config";

import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

import { useAuth } from "../../hooks/use-auth";

const { publicRuntimeConfig } = getConfig();
const enableDataDogRUM = publicRuntimeConfig.enableDataDogRUM === "true";

export default function DatadogUserManager() {
    const { userProfile } = useAuth();

    useEffect(() => {
        if (userProfile) {
            if (enableDataDogRUM) {
                datadogRum.setUser({
                    id: userProfile.id?.toString(),
                    name: `${userProfile.firstName} ${userProfile.lastName}`,
                    email: userProfile.email
                });
            }

            datadogLogs.setUser({
                id: userProfile.id?.toString(),
                name: `${userProfile.firstName} ${userProfile.lastName}`,
                email: userProfile.email
            });
        }
    }, [userProfile]);

    return null;
}
