import PortalAnalytics from "PortalAnalytics";
import { InputError } from "components/common/v2/InputError";
import { useFormikContext } from "formik";
import { getURL } from "lib/utils/url";
import Link from "next/link";
import { usePathname, useSearchParams } from "next/navigation";
import { ArrowSquareOut } from "phosphor-react";
import { useEffect, useMemo, useState } from "react";
import { If, PageSchema, Schema } from "types/form/schema";

const getNextPage = (
    schema: Schema,
    pageSchema: PageSchema,
    values: { [key: string]: string | number | null }
): number => {
    if (pageSchema.allOf) {
        const condition = pageSchema.allOf.find((logic: If) => {
            if (!Array.isArray(logic.if.oneOf)) return false;
            return !!logic.if.oneOf.find(
                (currentCondition: { [key: string]: string | number }) => {
                    for (let key in currentCondition) {
                        if (currentCondition.hasOwnProperty(key)) {
                            if (values[key] === currentCondition[key]) {
                                return true;
                            }
                        }
                    }
                    return false;
                }
            );
        });
        if (!condition) return -1;
        const nextPageSlug = condition.then?.next;
        return (
            schema.pages.findIndex(
                ({ slug }: { slug: string }) => slug === nextPageSlug
            ) || -1
        );
    }
    return -1;
};

interface Props {
    schema: Schema;
    currentPage: number;
    onPageChange: (page: number) => void;
    onSubmit: (values: { [key: string]: number | string }) => void;
    onDone?: () => void;
    error?: string;
    setSubmittedFirstTime?: (submitted: boolean) => void;
}
export const Navigation = ({
    schema,
    currentPage,
    onPageChange,
    onSubmit,
    onDone,
    error,
    setSubmittedFirstTime
}: Props) => {
    const pathname = usePathname();
    const searchParams = useSearchParams();
    const formURL = getURL(pathname, searchParams);
    const trackingProperties = {
        url: formURL,
        wizard_title: schema.title
    };
    const currentPageSchema = schema.pages[currentPage];
    const { values, validateForm } = useFormikContext<{
        [key: string]: number | string;
    }>();
    const url = useMemo(() => {
        if (currentPageSchema?.nextButton?.override) {
            const propertyId = currentPageSchema?.nextButton?.override?.id;
            const attribute =
                currentPageSchema?.nextButton?.override?.value || "name";

            const propertyCondition = Array.isArray(
                schema.properties[propertyId].oneOf
            )
                ? schema.properties[propertyId].oneOf
                : [];

            const currentValue = values[propertyId];
            const selectedProperty:
                | { id: string | number; name: string; url?: string }
                | undefined = propertyCondition?.find(
                ({ id }: { id: string | number }) => {
                    return id === currentValue;
                }
            );
            const url = selectedProperty?.[attribute];

            return `${url}`;
        }
        return undefined;
    }, [currentPageSchema]);
    useEffect(() => {
        PortalAnalytics.track("wizard__track_started", trackingProperties);
    }, []);
    const onNext = async () => {
        if (setSubmittedFirstTime) setSubmittedFirstTime(true);
        const result = await validateForm(values);
        if (JSON.stringify(result) === "{}") {
            if (currentPageSchema?.nextButton?.text === "Close" && onDone) {
                PortalAnalytics.track(
                    "wizard__track_close",
                    trackingProperties
                );
                onDone();
            } else if (currentPageSchema?.nextButton?.type === "submit") {
                PortalAnalytics.track(
                    "wizard__track_submit",
                    trackingProperties
                );
                onSubmit(values);
            } else {
                PortalAnalytics.track("wizard__track_next", trackingProperties);
                let next = getNextPage(schema, currentPageSchema, values);
                if (next > -1) next = next;
                if (schema.pages.length <= currentPage + 1) next = currentPage;
                if (next < 1) next = currentPage + 1;
                onPageChange(next);
            }
        }
    };
    if (url) {
        const isOutsideLink = !url?.includes("https://advisor.fora.travel");
        if (isOutsideLink) {
            return (
                <div className="action-button-bar ">
                    <Link
                        href={url}
                        target="_blank"
                        className="cursor-pointer inline-flex gap-1 items-center text-link whitespace-nowrap hover:underline"
                    >
                        <span>
                            {currentPageSchema?.nextButton?.text || "NEXT"}
                        </span>
                        <ArrowSquareOut className="ml-1" size={18} />
                    </Link>
                </div>
            );
        }
        return (
            <div className="action-button-bar ">
                <Link href={url} className="btn-primary btn-big">
                    <span>{currentPageSchema?.nextButton?.text || "NEXT"}</span>
                </Link>
            </div>
        );
    }
    return (
        <div className="action-button-bar justify-between">
            <div>
                {error && <InputError className="mr-2">{error}</InputError>}
            </div>
            <div className="action-button-bar">
                {onDone && (
                    <button className="btn-big" onClick={onDone} type="button">
                        Cancel
                    </button>
                )}
                <button
                    className="btn-primary btn-big"
                    onClick={onNext}
                    type="button"
                >
                    {currentPageSchema?.nextButton?.text || "NEXT"}
                </button>
            </div>
        </div>
    );
};
