import { signOut } from "next-auth/react";
import * as Sentry from "@sentry/nextjs";

import { URI_LOGIN } from "constants/urls";

export async function getData(url = "", token = "") {
    let headers: any = {
        "Content-Type": "application/json"
    };
    if (token) {
        headers = { ...headers, Authorization: `Token ${token}` }; // Bearer or Token
    }
    const response = await fetch(url, {
        headers,
        referrerPolicy: "no-referrer" // no-referrer, *client
    });

    if (
        !response.ok &&
        response.status === 401 &&
        typeof window !== "undefined"
    ) {
        signOut({
            callbackUrl: URI_LOGIN
        });
        return {};
    }

    return await response.json();
}

export async function getFullData(url = "", token = "", isJson = true) {
    let headers: any = {
        "Content-Type": "application/json"
        /*"Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "Origin, Content-Type, Accept"*/
    };
    if (token) {
        headers = { ...headers, Authorization: `Token ${token}` }; // Bearer or Token
    }
    const response = await fetch(url, {
        headers,
        referrerPolicy: "no-referrer" // no-referrer, *client
    });
    let data: any = {};

    if (
        !response.ok &&
        response.status === 401 &&
        typeof window !== "undefined"
    ) {
        signOut({
            callbackUrl: URI_LOGIN
        });
        return {
            data: {},
            response: {} as Response
        };
    }
    try {
        if (isJson) {
            try {
                data = await response.clone().json();
            } catch (e: any) {
                console.error(response.statusText);
                if (response.status <= 500) {
                    Sentry.captureException(e, {
                        contexts: {
                            response: {
                                statusText: response.statusText,
                                responseText: await response.text(),
                                fullResponse: response
                            }
                        }
                    });
                }
            }
        }
    } catch (e) {
        console.error(e);
        if (response.status <= 500) {
            Sentry.captureException(e, {
                contexts: {
                    response: {
                        statusText: response.statusText,
                        responseText: await response.text(),
                        fullResponse: response
                    }
                }
            });
        }
    }

    return { data, response };
}

export async function postData(url = "", data = {}, token = "") {
    let headers: any = {
        "Content-Type": "application/json"
    };
    if (token) {
        headers = { ...headers, Authorization: `Token ${token}` };
    }

    // Default options are marked with *
    const response = await fetch(url, {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers,
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *client
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });

    if (
        !response.ok &&
        response.status === 401 &&
        typeof window !== "undefined"
    ) {
        signOut({
            callbackUrl: URI_LOGIN
        });
        return null;
    }
    const bodyContent = await response.text();

    return bodyContent.length ? JSON.parse(bodyContent) : null; // parses JSON response into native JavaScript objects, handled case if response has content-type='application/json', but body is empty
}

export const sendData = async ({
    url = "",
    data = {},
    token = "",
    method = "POST",
    contentType = "application/json"
}: any) => {
    let headers: any;
    if (token) {
        headers = { ...headers, Authorization: `Token ${token}` };
    }

    let body: any;

    if (contentType === "multipart/form-data") {
        let formData = new FormData();
        Object.entries(data).map((item: any) => {
            // TODO specify backend API data
            if (item[1] instanceof File) {
                formData.append(item[0], item[1], item[1]?.name);
            } else {
                formData.append(item[0], item[1]);
            }
        });

        body = formData;
    }

    if (contentType === "application/json") {
        body = JSON.stringify(data);
        headers = { ...headers, "Content-Type": contentType };
    }

    // Default options are marked with *
    try {
        const response = await fetch(url, {
            method, // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers,
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *client
            body // body data type must match "Content-Type" header
        });
        if (response?.status >= 500) {
            throw response;
        }
        if (
            !response?.ok &&
            response?.status === 401 &&
            typeof window !== "undefined"
        ) {
            signOut({
                callbackUrl: URI_LOGIN
            });
            return {
                data: {},
                response: {} as Response,
                errors: null
            };
        }
        let responseData: any = {};
        try {
            if (response.body) {
                responseData = await response?.json();
            }
        } catch (e) {
            if (response.status <= 500) {
                Sentry.captureException(e);
            }
        }
        let errors: { [key: string]: string[] } = !response?.ok
            ? responseData
            : null;

        return {
            data: responseData,
            response,
            errors
        }; // parses JSON response into native JavaScript objects
    } catch (e: any) {
        return {
            data: undefined,
            response: undefined,
            errors: e
        };
    }
};

export const sendDataWithout50xErrors = async ({
    url = "",
    data = {},
    token = "",
    method = "POST",
    contentType = "application/json"
}: any) => {
    let headers: any;
    if (token) {
        headers = { ...headers, Authorization: `Token ${token}` };
    }

    let body: any;

    if (contentType === "multipart/form-data") {
        let formData = new FormData();
        Object.entries(data).map((item: any) => {
            // TODO specify backend API data
            if (item[1] instanceof File) {
                formData.append(item[0], item[1], item[1]?.name);
            } else {
                formData.append(item[0], item[1]);
            }
        });

        body = formData;
    }

    if (contentType === "application/json") {
        body = JSON.stringify(data);
        headers = { ...headers, "Content-Type": contentType };
    }

    // Default options are marked with *
    try {
        const response = await fetch(url, {
            method, // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers,
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *client
            body // body data type must match "Content-Type" header
        });
        if (
            !response?.ok &&
            response?.status === 401 &&
            typeof window !== "undefined"
        ) {
            signOut({
                callbackUrl: URI_LOGIN
            });
            return {
                data: {},
                response: {} as Response,
                errors: null
            };
        }
        console.log("response sendData", response);
        let responseData: any = {};
        try {
            if (response.body) {
                responseData = await response?.json();
            }
        } catch (e) {
            console.error(e);
            if (response.status <= 500) {
                Sentry.captureException(e);
            }
        }
        let errors: { [key: string]: string[] } = !response?.ok
            ? responseData
            : null;

        return {
            data: responseData,
            response,
            errors
        }; // parses JSON response into native JavaScript objects
    } catch (e: any) {
        console.log(e);

        return {
            data: undefined,
            response: undefined,
            errors: undefined
        };
    }
};

/*Legacy function. Don't recommend use for a new functionality*/
export async function postDataWithStatus(url = "", data = {}, token = "") {
    let headers: any = {
        "Content-Type": "application/json"
    };
    if (token) {
        headers = { ...headers, Authorization: `Token ${token}` };
    }

    // Default options are marked with *
    try {
        const response = await fetch(url, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers,
            redirect: "follow", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *client
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
        let errorData: any = {};
        if (
            !response.ok &&
            response.status === 401 &&
            typeof window !== "undefined"
        ) {
            signOut({
                callbackUrl: URI_LOGIN
            });
            return {
                errorData,
                response: {} as Response
            };
        }
        try {
            if (response.status !== 404) {
                errorData = await response.json();
            }
        } catch (e) {
            console.log("Error on parsing a POST response body", url);
        }

        return {
            errorData,
            response
        };
    } catch (e) {
        console.log(e);

        return {
            response: {
                status: 500 /*Set 500 for EVERY 50x errors. To save backward compatibility this function. I see everywhere is expecting an exist status*/
            },
            errorData: "Internal Server Error"
        };
    }
}

export class HttpRequests {
    static async get({
        url = "",
        token = "",
        isJson = true,
        signal = null
    }: {
        url: string;
        token?: string;
        isJson?: boolean;
        signal?: AbortSignal | null;
    }) {
        let headers: any = {
            "Content-Type": "application/json"
        };
        if (token) {
            headers = { ...headers, Authorization: `Token ${token}` }; // Bearer or Token
        }
        const response = await fetch(url, {
            headers,
            referrerPolicy: "no-referrer", // no-referrer, *client
            signal
        });
        let data: any = {};

        if (
            !response.ok &&
            response.status === 401 &&
            typeof window !== "undefined"
        ) {
            signOut({
                callbackUrl: URI_LOGIN
            });
            return {
                data: {},
                response: {} as Response
            };
        }
        try {
            if (isJson) {
                try {
                    data = await response.clone().json();
                } catch (e: any) {
                    console.error(response.statusText);
                    if (response.status <= 500) {
                        Sentry.captureException(e, {
                            contexts: {
                                response: {
                                    statusText: response.statusText,
                                    responseText: await response.text(),
                                    fullResponse: response
                                }
                            }
                        });
                    }
                }
            }
        } catch (e) {
            console.error(e);
            if (response.status <= 500) {
                Sentry.captureException(e, {
                    contexts: {
                        response: {
                            statusText: response.statusText,
                            responseText: await response.text(),
                            fullResponse: response
                        }
                    }
                });
            }
        }

        return { data, response };
    }
}
