import { twMerge } from "tailwind-merge";
import { Label } from "./Label";
import { InputError } from "./InputError";

interface Props
    extends Omit<React.ComponentPropsWithoutRef<"textarea">, "onChange"> {
    label?: string;
    error?: string;
    onChange: (value?: string | number) => void;
    required?: boolean;
}

export const Textarea = ({
    onChange,
    label,
    error,
    className,
    required,
    ...otherProps
}: Props) => {
    const borderStyle = error ? "border-error" : "";
    return (
        <div>
            <Label htmlFor={otherProps.id} required={required}>
                {label}
            </Label>
            <textarea
                {...otherProps}
                className={twMerge(
                    `form-control text-medium py-[11px] min-h-[167px] resize-none ${borderStyle} ${className}`
                )}
                onChange={e => {
                    onChange(e.target.value);
                }}
            ></textarea>
            <InputError className="mt-2">{error}</InputError>
        </div>
    );
};
