import { PageSchema } from "types/form/schema";

interface Props {
    schema: PageSchema;
}
export const SubmitScreen = ({ schema }: Props) => {
    return (
        <div
            className="form-help"
            dangerouslySetInnerHTML={{ __html: schema.description || "" }}
        />
    );
};
