import React, { useEffect } from "react";

import { Hotel } from "api/hotels";
import { useAuth } from "hooks/use-auth";
import { getFullData } from "utils/http-requests";
import { URL_HOTELS_SEARCH } from "constants/urls";
import { DEFAULT_PAGE_LIMIT } from "constants/index";

type Props = {
    children: JSX.Element;
    updateAppStore: (value: any) => void;
    appStore: any;
    isMobileView?: boolean;
};

export default function PrefetchDataLoader({
    children,
    updateAppStore,
    appStore,
}: Props) {
    const { token } = useAuth();
    const hotelsAPI = `${URL_HOTELS_SEARCH}?limit=${DEFAULT_PAGE_LIMIT}`;
    useEffect(() => {
        /*if (token && !appStore?.hotels) { TODO make sure that load make sense, because on hotels page we use new request on search api
            prefetchHotelAPI(hotelsAPI);
        }*/
    }, [token]);

    const prefetchHotelAPI = async (API: string) => {
        const { data: hotelsData, response } = await getFullData(API, token);
        if (response.ok) {
            const hotels: Hotel[] = hotelsData.results?.map(
                (item: any) => new Hotel(item)
            );

            updateAppStore({ ...appStore, hotels });
        }
    };

    return children;
}
