import { datadogLogs } from "@datadog/browser-logs";
import { Page } from "components/form/Page";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Navigation } from "./Navigation";
import * as Yup from "yup";
import { FormProperties, PageSchema } from "types/form/schema";
import { useSession } from "next-auth/react";
import { EndScreen } from "./pages/EndScreen";
import ClearLayout from "components/ClearLayout";
import Loader from "components/common/Loader";
import { postDataWithStatus } from "utils/http-requests";
import { URI_REQUEST_FORM } from "constants/urls";
import { useAuth } from "hooks/use-auth";

interface Props {
    schema: any;
    onDone?: () => void;
}
const getHumanReadable = (
    properties: FormProperties,
    values: { [key: string]: string | number | null }
) => {
    const result: { [key: string]: string | number | null } = {};

    for (const key in values) {
        if (properties[key]?.oneOf) {
            result[key] =
                properties?.[key]?.oneOf?.find(
                    ({ id }) => `${id}` === `${values[key]}`
                )?.name || "";
        } else {
            result[key] = values[key] || "";
        }
    }

    return result;
};
export const DynamicForm = ({ schema, onDone }: Props) => {
    const { token } = useAuth();
    const { data: session } = useSession();
    const [submittedFirstTime, setSubmittedFirstTime] =
        useState<boolean>(false);

    const [currentPage, setCurrentPage] = useState(0);
    const [state, setCurrentState] = useState<
        "success" | "error" | "loading" | undefined
    >();
    const currentPageSchema = schema.pages[currentPage];
    useEffect(() => {
        setSubmittedFirstTime(false);
    }, [currentPage]);
    const initialValues: { [key: string]: number | string } = {};
    const validations = schema.pages.map((page: PageSchema) => {
        let validationObj: {
            [key: string]: Yup.StringSchema | Yup.NumberSchema;
        } = {};
        page?.properties?.forEach((prop: string) => {
            const item = schema.properties[prop];
            const currentType: "string" | "number" = [
                "string",
                "number"
            ].includes(item?.type)
                ? item?.type
                : "string";
            const YupType = Yup?.[currentType]() || Yup;
            validationObj[prop] = item?.required
                ? YupType.required(`${item.name || "Field"} is required`)
                : YupType;
        });
        return Yup.object().shape(validationObj);
    });
    Object.keys(schema.properties).forEach((key: string) => {
        const currentProperty = schema.properties[key];
        initialValues[key] =
            currentProperty?.source === "session"
                ? session?.userProfile?.[key] || ""
                : "";
    });

    const currentValidationSchema = validations[currentPage];
    const reportError = (values: { [key: string]: string | number | null }) => {
        //remove sensitive data
        const { firstName, lastName, email, ...otherValues } = values;
        setCurrentState("error");
        datadogLogs.logger.error(`Error submitting user request`, otherValues);
    };

    const onSubmit = async (values: {
        [key: string]: string | number | null;
    }) => {
        setCurrentState("loading");
        const submitValues = schema.humanReadableSubmit
            ? getHumanReadable(schema.properties, values)
            : values;

        try {
            await postDataWithStatus(
                `${URI_REQUEST_FORM}`,
                submitValues,
                token
            ).then(({ response }) => {
                if (response.status >= 200 && response.status < 300) {
                    setCurrentState("success");
                } else if (response.status >= 400) {
                    reportError(values);
                }
            });
        } catch (e) {
            reportError(values);
        }
    };
    if (state === "loading") {
        <ClearLayout>
            <Loader isSpin={true} />
        </ClearLayout>;
    }
    if (state === "success") {
        return (
            <EndScreen
                title="Thank you for contacting us!"
                description="We will respond your request soon"
                onDone={onDone}
                status={state}
            />
        );
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={currentValidationSchema}
            >
                {({ handleSubmit }) => (
                    <form>
                        <section className="mb-8 mt-2">
                            <Page
                                properties={schema.properties}
                                schema={currentPageSchema}
                                submittedFirstTime={submittedFirstTime}
                            />
                        </section>

                        <Navigation
                            schema={schema}
                            currentPage={currentPage}
                            onPageChange={setCurrentPage}
                            onSubmit={() => handleSubmit()}
                            onDone={onDone}
                            error={
                                state === "error"
                                    ? "There was an unexpected error, please try again"
                                    : ""
                            }
                            setSubmittedFirstTime={setSubmittedFirstTime}
                        />
                    </form>
                )}
            </Formik>
        </>
    );
};
