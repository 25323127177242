import { twMerge } from "tailwind-merge";
import { useState } from "react";
import CoreDropdown from "../Dropdown";
import { InputError } from "./InputError";
import { Label } from "./Label";

interface Props {
    id?: string;
    options: { id: string | number; name: string }[];
    value?: string | number;
    onChange: (value?: string | number) => void;
    label?: string;
    placeholder?: string;
    error?: string;
    className?: string;
    required?: boolean;
}
//TODO Integrate Dropdown to have only one component
//TODO To be accessible we probably want to use a select/option instead of button
export const Dropdown = ({
    id,
    options,
    value,
    onChange,
    label,
    placeholder,
    error,
    className,
    required,
    ...otherProps
}: Props) => {
    const [isShowing, setIsShowing] = useState<boolean>(false);
    const selection = options.find(option => option.id === value);

    const onChangeProxy = (value?: string | number) => {
        onChange(value);
        setIsShowing(false);
    };

    return (
        <div>
            <Label htmlFor={id} required={required}>
                {label}
            </Label>
            <CoreDropdown
                {...otherProps}
                id={id}
                label={
                    selection?.name || (
                        <span className="text-tertiary">{placeholder}</span>
                    ) ||
                    ""
                }
                isShowing={isShowing}
                setIsShowing={isShowing => {
                    setIsShowing(isShowing);
                }}
                menuPosClassName={"left-auto right-0 "}
                menuClassName={twMerge(
                    `!py-2 !w-full !mt-1 !shadow-button !text-tertiary ${className}`
                )}
                buttonClassName={`${error && "border-error"}`}
                classNamesContainer="!w-full "
                showFooter={false}
            >
                {options.map(item => {
                    const selectionClass =
                        `${value}` === `${item.id}`
                            ? "bg-successLight font-bold"
                            : "font-normal";
                    return (
                        <div
                            key={item.id}
                            id={`option-${item.id}`}
                            onClick={() => onChangeProxy(item.id)}
                            className={`block py-2 text-medium text-main text-left px-4 mb-2 last:mb-0 cursor-pointer hover:bg-successLight hover:font-bold ${selectionClass}`}
                        >
                            {item.name}
                        </div>
                    );
                })}
            </CoreDropdown>
            <InputError className="mt-2">{error}</InputError>
        </div>
    );
};
