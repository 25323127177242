import getConfig from "next/config";

// Only holds serverRuntimeConfig and publicRuntimeConfig
const { publicRuntimeConfig } = getConfig();

export const URL_BASE = `${publicRuntimeConfig.nextBase}/v1/`;
export const URL_BASE_V1_1 = `${publicRuntimeConfig.nextBase}/v1.1/`;
export const URL_BASE_V2 = `${publicRuntimeConfig.nextBase}/v2/`;
export const URL_ADMIN_USERS_LIST = `${publicRuntimeConfig.nextAdminPortal}/user/`;
export const URL_HOTELS = `${URL_BASE}hotels/`;
export const URL_HOTELS_SEARCH = `${URL_BASE}hotels/search/`;
export const URL_BRANDS_LEGACY = `${URL_BASE}brands/`;
export const URL_BRANDS = `${URL_BASE}supplier-database/supplier-brands/`;
export const URL_SUPPLIER_DATABASE = `${URL_BASE}supplier-database/`;
export const URL_SUPPLIERS = `${URL_SUPPLIER_DATABASE}suppliers/`;
export const URL_SUPPLIER_TYPES = `${URL_SUPPLIER_DATABASE}supplier-types/`;
export const URL_USER_PROFILE = `${URL_BASE}profile/`;
export const URL_USER_ONBOARDING = `${URL_BASE}profile/onboarding/`;
export const URL_COMMISSIONS_V2 = `${URL_BASE_V2}commissions/`;
export const URL_COMMISSIONS_V2_DOWNLOAD = `${URL_COMMISSIONS_V2}download/`;
export const URL_COMMISSIONS_V2_SUMMARIES = `${URL_COMMISSIONS_V2}summaries/`;
export const URL_REGISTRATION = `${URL_BASE}register/`;
export const URL_CHATBOT = `${URL_BASE}ai-conversations/`;
export const URL_CHATBOT_FEEDBACK = `${URL_BASE}ai-messages/`;
export const getURLRegisterValidation = (key: string) =>
    `${URL_BASE}register/${key}/profile_validation/`;
export const getURLRegisterSubscriptionStatus = (
    key: string,
    productId: string = ""
) =>
    `${URL_BASE}register/${key}/subscription_status/${
        productId ? `?product_id=${productId}` : ""
    }`;
export const URL_PHONE_CODES = `${URL_BASE}phone-codes/`;
export const URL_COUNTRIES = `${URL_BASE}countries/`;
export const URL_RESET_PASSWORD = `${URL_BASE}password-reset/`;
export const URL_RESET_PASSWORD_CONFIRM = `${URL_RESET_PASSWORD}confirm/`;
export const URL_RESET_PASSWORD_VALIDATE_TOKEN = `${URL_RESET_PASSWORD}validate_token/`;
export const URL_TIMEZONES = `${URL_BASE}timezones/`;
export const URL_HOTELPAGE_SVG = `${publicRuntimeConfig.staticHost}`;
export const URL_STATIC_PAGES = `${URL_BASE}static-pages/`;
export const URL_FEATURES = `${URL_BASE_V2}features/`;
export const URL_DASHBOARD = `${URL_BASE}dashboard/`;
export const URL_TRAININGS = `${URL_BASE}training/tracks/`;
export const URL_PERSONAL_STRIPE_CABINET = `${URL_BASE}profile/personal-stripe-cabinet/`;
export const URL_BANNERS_LIST = `${URL_BASE}banners/`;
export const URL_DASHBOARD_BOOKINGS = `${URL_BASE_V2}commissions/dashboard/`;
export const URL_VALIDATE_PHONE_NUMBER = `${URL_BASE}validate-phone/`;
export const URL_LOYALTY_PROGRAMS = `${URL_BASE}loyalty-programs`;
export const URL_SUPPLIER_PARTNER_PROGRAMS_LIST = `${URL_SUPPLIER_DATABASE}partner-programs/`;
export const URL_CALENDLY_SCHEDULING =
    publicRuntimeConfig.calendlySchedulingUrl;
export const getUrlSupplierProgramsByType = (slug: string) =>
    `${URL_SUPPLIERS}${slug}/programs/`;
export const URL_CLIENTS = `${URL_BASE}clients/`;

export const URL_TRAVEL_REVIEW = `${URL_BASE}travel_review/`;
export const getSecureLinkAddCard = (uriLink: string) =>
    uriLink &&
    publicRuntimeConfig.secureLinkAddCard &&
    publicRuntimeConfig.secureLinkAddCard !== "undefined"
        ? `${publicRuntimeConfig.secureLinkAddCard}${uriLink}`
        : "Something went wrong. Try please again";
export const getUrlReassignClientOnBooking = (bookingId: string) =>
    `${URL_COMMISSIONS_V2}${bookingId}/reassign/`;
export const URL_SUPPLIER_RATES_V1 = `${URL_BASE}supplier/rates/`;
export const URL_SUPPLIER_RATES_V2 = `${URL_BASE_V2}supplier/rate_summary/`;
export const URL_CURRENCIES_LIST = `${URL_BASE}currencies/`;

export const URI_HOME = "/";
export const URI_LOGIN = "/login";
export const URI_LOGOUT = "/logout";
export const URI_ONBOARDING = "/onboarding";
export const URI_ONBOARDING_MVP = "/onboarding_session";
export const URL_CANCELLATION_WORKFLOW = `${URL_BASE_V2}CancellationReasons/`;
export const URI_RESTORE_PASSWORD = "/restore-password";
export const URI_RESET_PASSWORD = "/reset-password";
export const URI_HOTELS = "/hotels";
export const URI_USERS = "/users";
export const URI_HOTEL_PERKS = "/hotel-perks";
export const URI_HOW_TO_BOOK = "/s/book-instructions";
export const URI_TRAINING_V2 = "/training";
export const URI_COMMISSIONS = "/bookings";
export const URI_COMMUNITY = "/community";
export const URI_USER_PROFILE = "/settings";
export const URI_SUPPLIERS = "/browse-book";
export const URI_PARTNERS_SUPPLIERS = "/partners";
export const URI_SUPPLIERS_PROGRAMS = `${URI_PARTNERS_SUPPLIERS}/programs`;
export const URI_HOTELS_WITH_PERKS = `${URI_SUPPLIERS}/hotels-with-perks`;
export const URI_BOOKING_PLATFORM_HOTELS = `${URI_SUPPLIERS}/hotels`;
export const URI_PARTNERS = `${URI_PARTNERS_SUPPLIERS}/hotels`;
export const URI_PACKAGES = `${URI_PARTNERS_SUPPLIERS}/packages`;
export const URI_REGISTRATION_SUCCESS = "/register/success";
export const URI_SUPPLIERS_PERKS = "/s/suppliers-perks";
export const URI_RESOURCES = "/s/resources";
export const URI_HELP_PORTAL = "/help";
export const URI_CHATBOT = "/chatbot";
export const URI_MARKETING = "/marketing";
export const URI_WHATS_NEW = "/whats-new";
export const URI_FORA_EMAIL = "/mail";
export const URI_CLIENTS = "/clients";
export const URI_CLIENT_VIEW = `${URI_CLIENTS}/client-view`;
export const URI_CLIENT_VIEW_INFORMATION = `${URI_CLIENTS}/client-view-information`;
export const URI_CLIENT_ADD = `${URI_CLIENTS}/add-client`;
export const URI_SUPPLIER_RATE = `${URL_BASE}supplier/rate/`;
export const URI_SUPPLIER_BOOK = `${URL_BASE}supplier/book/`;
export const URI_ISSUES = `${URL_BASE}issues/`;
export const URI_REQUEST_FORM = `${URL_BASE}user-request/`;
export const URI_ISSUES_REASONS = `${URL_BASE}issue-reasons/`;
export const URI_ISSUES_CATEGORIES = `${URL_BASE}issue-categories/`;
export const URI_ISSUES_SEVERITIES = `${URL_BASE}issue-severities/`;

export const CONTENTFUL_BASE_URL =
    "https://graphql.contentful.com/content/v1/spaces/";
