import uiConfig from "ui-static-data.json";
import { UiConfigType } from "types/index";

export const getStaticGoogleLink = (urls: string[] = []) => {
    let parsedUrl: any = "";
    // @ts-ignore
    parsedUrl = uiConfig[urls[0]][urls[1]] || "";

    const searchParams = new URL(parsedUrl);

    const queryDivider = [...searchParams.searchParams.keys()].length
        ? "&"
        : "?";

    parsedUrl = `${parsedUrl}${queryDivider}authuser=`;

    return parsedUrl;
};

export const getApiGoogleLink = (url: string = "") => {
    const urlPatterns = ["docs.google.com", "drive.google.com"];
    let parsedUrl: any = url;

    if (urlPatterns.some(pattern => url.includes(pattern))) {
        parsedUrl = `${url}authuser=`;
    }

    return parsedUrl;
};
