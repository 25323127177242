import React, { createContext, useContext, useState, useMemo } from "react";

import { Commissions } from "api/commissions/commissions";
import { SupplierTab } from "lib/types/supplier-database.types";

export type BookingsStore = {
    backUrl: string;
    setBackUrl: (value: string) => void;
    reportIssueMetadata?: any;
};

export type Store = {
    data: {
        bookings: Commissions[];
    };
    getData?: () => void;
    filters?: any;
    setFilters?: (filters: any) => any;
    errors?: any;
    setErrors?: (errors: any) => any;
    bookingFiltersURI?: string;
    setBookingFiltersURI?: (value: string) => void;
    suppliersNavigation?: SupplierTab[];
    setSuppliersNavigation?: (value: any[]) => void;
    bookingPlatform: BookingsStore;
    reportIssueMetadata: any;
    isHotelsVisitedHistory?: boolean;
    setIsHotelsVisitedHistory?: (value: boolean) => void;
};
export const initialStore: Store = {
    data: {
        bookings: []
    },
    filters: [],
    setFilters: (_value: any) => [],
    errors: [],
    setErrors: (_value: any) => [],
    bookingFiltersURI: "",
    suppliersNavigation: [],
    setSuppliersNavigation: ([]) => {},
    bookingPlatform: {
        backUrl: "",
        setBackUrl: ([]) => {}
    },
    reportIssueMetadata: {}
};
export const StoreContext = createContext(initialStore);

export const StoreProvider = ({ children }: any) => {
    const [filters, setFilters] = useState<any[]>([]);
    const [errors, setErrors] = useState<any[]>([]);
    const [bookingFiltersURI, setBookingFiltersURI] = useState<string>("");
    const [suppliersNavigation, setSuppliersNavigation] = useState<
        SupplierTab[]
    >([]);
    const [bookingPlatform, setBookingPlatform] = useState<BookingsStore>(
        initialStore.bookingPlatform
    );
    const [isHotelsVisitedHistory, setIsHotelsVisitedHistory] =
        useState<boolean>(false);
    const [reportIssueMetadata, setReportIssueMetadata] = useState(null);
    const value = useMemo(
        () => ({
            filters,
            setFilters,
            data: {
                bookings: []
            },
            errors,
            setErrors,
            bookingFiltersURI,
            setBookingFiltersURI,
            suppliersNavigation,
            setSuppliersNavigation,
            bookingPlatform,
            setBookingPlatform,
            reportIssueMetadata,
            setReportIssueMetadata,
            setIsHotelsVisitedHistory,
            isHotelsVisitedHistory
        }),
        [
            filters,
            errors,
            bookingFiltersURI,
            suppliersNavigation,
            bookingPlatform,
            setBookingPlatform,
            reportIssueMetadata,
            setReportIssueMetadata,
            setIsHotelsVisitedHistory,
            isHotelsVisitedHistory
        ]
    );
    return (
        <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
    );
};

export function useThemeContext() {
    return useContext(StoreContext);
}
