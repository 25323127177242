import React, { useState } from "react";
import { Combobox } from "@headlessui/react";
import { CaretDown } from "phosphor-react";
import { twMerge } from "tailwind-merge";
import { InputError } from "./InputError";
import { Label } from "./Label";

interface TypedownOption {
    id: string | number;
    name: string;
}

interface Props {
    id?: string;
    options: TypedownOption[];
    value?: any;
    onChange: (value: string | number) => void;
    label?: string;
    placeholder?: string;
    className?: string;
    error?: string;
    "data-testid"?: string;
    required?: boolean;
}

export const Typedown = ({
    id,
    options,
    value,
    onChange,
    label,
    placeholder,
    className,
    error,
    required,
    ...otherProps
}: Props) => {
    const currentOption: TypedownOption | undefined = options?.find(
        ({ id }) => `${id}` === `${value}`
    );
    const errorClass = error ? "border-error" : "";

    const [typedownInput, setTypedownInput] = useState<string>("");
    const filteredOptions = options.filter((option: TypedownOption) =>
        option?.name?.toLowerCase()?.includes(typedownInput?.toLowerCase())
    );
    const comboClassName = twMerge(`${errorClass} ${className}`);

    return (
        <div>
            <Label htmlFor={id} required={required}>
                {label}
            </Label>
            <Combobox
                id={id}
                as="div"
                value={currentOption}
                onChange={option => onChange(option.id)}
            >
                {({ open }) => (
                    <div className="relative">
                        <Combobox.Input
                            {...otherProps}
                            className={twMerge(
                                `w-full text-medium text-left rounded-b-none hover:bg-transparent rounded-t border-lightGreyBorder focus:border-lightGreyBorder focus:ring-0 dropdown-button px-4 py-3 placeholder:text-tertiary ${comboClassName}`
                            )}
                            onChange={event =>
                                setTypedownInput(event.target.value)
                            }
                            displayValue={(option: TypedownOption) =>
                                option?.name
                            }
                            placeholder={placeholder}
                            data-testid={
                                otherProps?.["data-testid"] ||
                                currentOption?.name ||
                                "typedown"
                            }
                        />
                        <Combobox.Button
                            className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-4 focus:outline-none"
                            data-testid={
                                otherProps?.["data-testid"]
                                    ? `${otherProps?.["data-testid"]}-caret`
                                    : ""
                            }
                        >
                            <CaretDown
                                size={15}
                                className={`-mt-[2px] ml-1 transition-all fill-secondary ${
                                    open ? "rotate-180" : ""
                                }`}
                            />
                        </Combobox.Button>
                        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {filteredOptions.length > 0 ? (
                                <>
                                    {filteredOptions.map(
                                        (option: TypedownOption) => (
                                            <Combobox.Option
                                                key={option.id}
                                                value={option}
                                                id={`prefix-option-${option.id}`}
                                                className="py-2 px-4 text-medium font-bold cursor-pointer rounded-[3px] text-secondary ui-active:bg-lightGreenBg ui-active:text-main"
                                            >
                                                {option.name}
                                            </Combobox.Option>
                                        )
                                    )}
                                </>
                            ) : (
                                <Combobox.Option
                                    value={null}
                                    disabled
                                    className="py-2 px-4 text-medium text-center text-gray-400 rounded-[3px]"
                                >
                                    No options
                                </Combobox.Option>
                            )}
                        </Combobox.Options>
                    </div>
                )}
            </Combobox>
            <InputError className="mt-2">{error}</InputError>
        </div>
    );
};
