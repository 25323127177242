import Head from "next/head";

import { appPageTitleSuffix } from "constants/index";

export default function ClearLayout({
    children,
    title = ""
}: {
    children: React.ReactNode;
    title?: string;
}) {
    return (
        <div className="min-h-screen">
            <Head>
                <title>{title ? `${title} - ${appPageTitleSuffix}` : ""}</title>
            </Head>
            <div className="min-h-screen h-full flex flex-1">{children}</div>
        </div>
    );
}
