import React from "react";
import { SimpleModal } from "./SimpleModal";
import { RequestSupplierLoginForm } from "components/form/forms/RequestSupplierLoginForm";

export const RequestSupplierLoginModal = () => {
    return (
        <SimpleModal title="Request a Supplier Login">
            {({ closeModal }) => (
                <RequestSupplierLoginForm onDone={closeModal} />
            )}
        </SimpleModal>
    );
};
