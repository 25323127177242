import { twMerge } from "tailwind-merge";
import { InputError } from "./InputError";
import { Label } from "./Label";

interface Props
    extends Omit<React.ComponentPropsWithoutRef<"input">, "onChange"> {
    label?: string;
    error?: string;
    onChange: (value?: string | number) => void;
    required?: boolean;
}

export const Input = ({
    onChange,
    label,
    error,
    id,
    className,
    required,
    ...otherProps
}: Props) => {
    const borderStyle = error ? "border-error" : "";
    return (
        <div>
            <Label htmlFor={id} required={required}>
                {label}
            </Label>
            <input
                {...otherProps}
                id={id}
                className={twMerge(
                    `shadow-0 text-medium focus:ring-0 focus:border-main block w-full border-stroke rounded py-[11px] px-4 placeholder:text-main/50 form-control wrapper-test-field ${borderStyle} ${className}`
                )}
                onChange={e => onChange(e.target.value)}
            />
            <InputError className="mt-2">{error}</InputError>
        </div>
    );
};
