import { Dropdown } from "components/common/v2/Dropdown";
import { Typedown } from "components/common/v2/Typedown";
import { useFormikContext } from "formik";
import { SubmitScreen } from "components/form/pages/SubmitScreen";
import { Textarea } from "../common/v2/Textarea";
import { Input } from "../common/v2/Input";
import { FormProperties, PageSchema } from "types/form/schema";

const Pages: { [key: string]: (props: any) => JSX.Element } = {
    SubmitScreen
};

export const Page = ({
    schema,
    properties,
    submittedFirstTime
}: {
    schema: PageSchema;
    properties: FormProperties;
    submittedFirstTime: boolean;
}) => {
    const { values, errors, setFieldValue, touched } = useFormikContext<{
        [key: string]: number | string;
    }>();

    if (schema?.component && Pages[schema.component]) {
        const PageComponent = Pages[schema.component];
        return <PageComponent schema={schema} properties={properties} />;
    }
    return (
        <>
            {schema.properties?.map((propertyId: string) => {
                const value: string | number | undefined = values[propertyId];
                const currentProperty = properties[propertyId];
                const updateValue = (value?: string | number | undefined) => {
                    setFieldValue(propertyId, value, true);
                };
                const error =
                    touched[propertyId] || submittedFirstTime
                        ? errors[propertyId]
                        : undefined;
                if (
                    currentProperty.oneOf ||
                    currentProperty.component === "Typedown"
                ) {
                    const MultipleChoiceComponent =
                        currentProperty.component === "Typedown"
                            ? Typedown
                            : Dropdown;

                    return (
                        <div className="mb-6" key={propertyId}>
                            <MultipleChoiceComponent
                                id={propertyId}
                                options={currentProperty.oneOf || []}
                                label={currentProperty.title}
                                placeholder={currentProperty.placeholder}
                                value={value}
                                onChange={updateValue}
                                error={error}
                                data-testid={propertyId}
                                required={currentProperty.required}
                            />
                        </div>
                    );
                }
                if (currentProperty.component === "Textarea") {
                    return (
                        <div className="mb-6" key={propertyId}>
                            <Textarea
                                id={propertyId}
                                label={currentProperty.title}
                                placeholder={currentProperty.placeholder}
                                value={value}
                                onChange={updateValue}
                                error={error}
                                required={currentProperty.required}
                            />
                        </div>
                    );
                }

                return (
                    <div className="mb-6" key={propertyId}>
                        <Input
                            id={propertyId}
                            label={currentProperty.title}
                            placeholder={currentProperty.placeholder}
                            value={value}
                            onChange={updateValue}
                            error={error}
                            required={currentProperty.required}
                        />
                    </div>
                );
            })}
        </>
    );
};
