import React, { useEffect } from "react";
import { useModalManager } from "../../hooks/use-modal-manager";
import { ReportIssueModal } from "../clients/modals/ReportIssueModal";
import { TestModal } from "./modals/TestModal";
import { RequestSupplierLoginModal } from "./modals/RequestSupplierLoginModal";

export const ModalManager = () => {
    const { modalId } = useModalManager();

    useEffect(() => {
        const root = document.getElementsByTagName("html")[0];
        if (!modalId) {
            root.classList.remove("fixed");
            const lastYPos = root.style.getPropertyValue("top");
            root.style.removeProperty("top");
            window.scrollTo(
                0,
                parseInt(lastYPos.replace("-", "").replace("px", ""))
            );
        } else {
            root.style.setProperty("top", `-${window.scrollY}px`);
            root.classList.add("fixed");
        }
    }, [modalId]);

    if (!modalId) return null;
    if (modalId === "report-issue") return <ReportIssueModal />;
    if (modalId === "request-supplier-login")
        return <RequestSupplierLoginModal />;
    if (modalId === "test") return <TestModal />;
    return null;
};
