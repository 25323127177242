import { X } from "phosphor-react";
import React, { ReactNode } from "react";
import { ModalHeader } from "./ModalHeader";
// This is to give backward compatibility to existing modals. It is not encouraged to use this, please use BaseModal instead

export interface ModalProps {
    title?: string | ReactNode;
    description?: string | ReactNode;
    children: ReactNode;
    showClose?: boolean;
    maxWidth?: number | string;
    onClose?: () => void;
}
export const Modal = ({
    title,
    description,
    children,
    showClose = true,
    maxWidth = 585,
    onClose
}: ModalProps) => {
    const maxWidthClasses = maxWidth
        ? `max-w-[${maxWidth}px] md:w-[${maxWidth}px]`
        : "";
    return (
        <div
            className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full max-h-full flex"
            onClick={onClose}
        >
            <div className="dialog-backdrop fixed inset-0 bg-charcoal/70" />
            <div
                className="relative w-full max-w-2xl mx-auto"
                onClick={e => e.stopPropagation()}
            >
                <div
                    className={`relative bg-white rounded-lg shadow dark:bg-gray-700 px-6 py-8 lg:px-[31px] w-full ${maxWidthClasses}`}
                >
                    {(title || (showClose && onClose)) && (
                        <ModalHeader
                            title={title}
                            onDone={showClose ? onClose : undefined}
                        />
                    )}
                    <div className="space-y-4">
                        {description && (
                            <p className="text-medium text-secondary font-normal text-left">
                                {description}
                            </p>
                        )}
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};
