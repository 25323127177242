import { DynamicForm } from "components/form/DynamicForm";
import REQUEST_SUPPLIER_LOGIN_FORM_SCHEMA from "components/form/schemas/RequestSupplierLoginSchema.json";
import { ModalHeader } from "components/modal/modals/ModalHeader";

interface Props {
    onDone?: () => void;
}

export const RequestSupplierLoginForm = ({ onDone }: Props) => {
    return (
        <DynamicForm
            schema={REQUEST_SUPPLIER_LOGIN_FORM_SCHEMA}
            onDone={onDone}
        />
    );
};
