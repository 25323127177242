import { ApiSelectOption } from "api/common/ApiSelectOption";

export const HOTEL_CLASSES = ["0", "1", "2", "3", "4", "5"];
export const UNRATED_HOTEL_VALUE = "0";
export const UNRATED_HOTEL_KEY = "Unrated";

export const GUARANTEE_PAYMENT_TYPE = "Guarantee";

export enum SupplierListSortOptions {
    RECOMMENDED = "sequence",
    ALPHABETICALLY = "name",
    HOTEL_CLASS = "hotel_class",
    NEG_HOTEL_CLASS = "-hotel_class"
}

export const SupplierListSortOptionLabels = {
    [SupplierListSortOptions.RECOMMENDED]: "Recommended",
    [SupplierListSortOptions.ALPHABETICALLY]: "Alphabetically",
    [SupplierListSortOptions.HOTEL_CLASS]: "Hotel class (low to high)",
    [SupplierListSortOptions.NEG_HOTEL_CLASS]: "Hotel class (high to low)"
};

export const allowedCurrencies = ["USD", "GBP", "EUR", "JPY"];

export enum CurrenciesSelectorValues {
    LOCAL_CURRENCY = "",
    USD = "USD",
    EUR = "EUR",
    GBP = "GBP",
    AUD = "AUD",
    CAD = "CAD"
}

export const currencyOptionsList: ApiSelectOption[] = [
    {
        value: CurrenciesSelectorValues.LOCAL_CURRENCY,
        label: "Hotel's currency"
    },
    {
        value: CurrenciesSelectorValues.USD,
        label: "USD",
        rawData: "($)"
    },
    {
        value: CurrenciesSelectorValues.EUR,
        label: "EUR",
        rawData: "(€)"
    },
    {
        value: CurrenciesSelectorValues.GBP,
        label: "GBP",
        rawData: "(£)"
    },
    {
        value: CurrenciesSelectorValues.CAD,
        label: "CAD",
        rawData: ""
    },
    {
        value: CurrenciesSelectorValues.AUD,
        label: "AUD",
        rawData: ""
    }
];

export enum TAG_SLUGS {
    REFUNDABILITY = "refundability",
    PAYMENT_TYPE = "payment_type"
}

export const defaultNumberOfTravelers = 2;

export enum REFUNDABILITY_TYPES {
    NONREFUNDABLE = "Non-refundable",
    REFUNDABLE = "Refundable",
    PARTIALLY_REFUNDABLE = "Partially Refundable"
}

export const MIN_NUMBER_OF_TRAVELERS = 1;
export const MAX_NUMBER_OF_TRAVELERS = 9;
