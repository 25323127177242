import { X } from "phosphor-react";
import { ReactNode } from "react";

interface Props {
    title?: string | ReactNode;
    onDone?: () => void;
}
export const ModalHeader = ({ title, onDone }: Props) => {
    if (!title && !onDone) return null;
    return (
        <div className="flex items-center justify-between">
            <span className="flex w-full items-center">
                {title && (
                    <h3 className="text-header font-bold text-left mb-2 flex-1">
                        {title}
                    </h3>
                )}
                {onDone && (
                    <X
                        size={18}
                        className={" fill-main cursor-pointer"}
                        onClick={onDone}
                    />
                )}
            </span>
        </div>
    );
};
